import { FC } from 'react';
import clsx from 'clsx';
import AppIconLoader from '../Icons';
import AppCircularLoader from '../Circular';
import useLoaderStyles from './useLoaderStyles';

export interface IPageLoader {
  type: 'icon' | 'circular';
  className?: string;
}

/* loader wrapper that takes size based on parent */
const PageLoader: FC<IPageLoader> = ({ type = 'icon', className }) => {
  const css = useLoaderStyles();
  const Loader = type === 'icon' ? AppIconLoader : AppCircularLoader;

  return <Loader className={clsx(css.loader, className)} />;
};

export default PageLoader;
