import { FC } from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { IActionButton } from './types';

const ActionButton: FC<IActionButton> = (props) => {
  const {
    label,
    icon,
    classes,
    isSubmitting,
    loaderSize = 20,
    circularProgressClasses,
    ...buttonProps
  } = props;
  const css = useStyles();
  const customClasses = {
    ...classes,
    root: clsx(css.root, classes?.root),
    endIcon: clsx(css.endIcon, classes?.endIcon),
  };

  return (
    <Button
      {...buttonProps}
      classes={customClasses}
      endIcon={
        isSubmitting ? (
          <CircularProgress
            data-testid="actionButtonCircularProgress"
            color="inherit"
            size={loaderSize}
            thickness={4}
            classes={circularProgressClasses}
          />
        ) : (
          icon
        )
      }
    >
      {label}
    </Button>
  );
};

export default ActionButton;
