import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
    },
    containedPrimary: {
      background: theme.oysterTheme.oyster,
    },
    containedSecondary: {
      background: theme.oysterTheme.stone,
      color: theme.oysterTheme.pine,
    },
  }),
);

export default useStyles;
