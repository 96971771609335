import { FC, MouseEventHandler } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Fade from '@material-ui/core/Fade';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

import { AddPlainIcon, SubtractPlainIcon } from '../../icons';
import ActionButton from '../ActionButton';
import { IInfoCard } from './types';
import useStyles from './styles';

const InfoCard: FC<IInfoCard> = ({
  title,
  titleColor = 'inherit',
  message,
  messageColor = 'inherit',
  imageSrc,
  imageHeight = 280,
  imageAlt,
  open = false,
  actionButton,
  children,
  classes,
  onToggle = () => null,
}) => {
  const css = useStyles();

  const customClasses = {
    root: clsx(css.root, classes?.root),
    content: clsx(css.content, classes?.content),
    title: clsx(css.title, classes?.title),
    message: clsx(css.message, classes?.message),
    imageContainer: clsx(css.imageContainer, classes?.imageContainer),
    extraInfoButton: clsx(css.extraInfoButton, classes?.extraInfoButton, open && 'active'),
    extraInfoContainer: clsx(css.extraInfoContainer, classes?.extraInfoContainer),
    actionButton: clsx(css.actionButton, actionButton?.classes?.root),
  };

  const handleToggleMoreClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    onToggle(event);
  };

  return (
    <>
      <Card className={customClasses.root}>
        <CardContent className={customClasses.content}>
          <Typography className={customClasses.title} variant="h3" color={titleColor}>
            {title}
          </Typography>
          <Typography
            className={customClasses.message}
            component="p"
            variant="body2"
            color={messageColor}
          >
            {message}
          </Typography>
        </CardContent>
        <Box className={customClasses.imageContainer}>
          <CardMedia
            data-testid="image"
            className={classes?.image}
            component="img"
            height={imageHeight}
            image={imageSrc}
            alt={imageAlt}
          />
          {children && (
            <>
              <IconButton
                data-testid="toggleButton"
                className={customClasses.extraInfoButton}
                onClick={handleToggleMoreClick}
              >
                {open ? (
                  <SubtractPlainIcon data-testid="hideIcon" />
                ) : (
                  <AddPlainIcon data-testid="showIcon" />
                )}
              </IconButton>
              <Fade in={open} mountOnEnter unmountOnExit>
                <Box className={customClasses.extraInfoContainer}>{children}</Box>
              </Fade>
            </>
          )}
        </Box>
      </Card>
      {actionButton && (
        <ActionButton
          {...actionButton}
          classes={{
            ...actionButton?.classes,
            root: customClasses.actionButton,
          }}
        />
      )}
    </>
  );
};

InfoCard.displayName = 'InfoCard';

export default InfoCard;
