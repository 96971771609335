import { SVGProps } from 'react';
import { oysterTheme } from '@theme/.';

export interface IconProps extends SVGProps<SVGSVGElement> {
  defaultColor?: boolean;
  'data-testid'?: string;
}

export const defaultIconColor = oysterTheme.dolphin;
export const alertRed = oysterTheme.ferrari;
export const pine = oysterTheme.pine;
export const oyster = oysterTheme.oyster;
export const obsidian = oysterTheme.obsidian;
export const opal = oysterTheme.opal;
export const pineapple = oysterTheme.pineapple;
export const water = oysterTheme.water;
export const peacock = oysterTheme.peacock;
export const cerulean = oysterTheme.cerulean;
export const brandGreen = oysterTheme.brandGreen;
export const navy = oysterTheme.navy;
export const salmon = oysterTheme.salmon;
export const mango = oysterTheme.mango;
export const charcoal = oysterTheme.charcoal;
export const cotton = oysterTheme.cotton;
