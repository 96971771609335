import { FC } from 'react';
import { IconProps, oyster } from './types';

const CheckboxFilled: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="checkboxFilled"
      {...props}
      fill="none"
    >
      <rect
        y={0.5}
        width={24}
        height={24}
        rx={12}
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.365 7.797c.444.416.468 1.115.054 1.56l-7.499 8.076a1.097 1.097 0 01-1.553.054L5.348 13.72a1.108 1.108 0 01-.053-1.561 1.096 1.096 0 011.553-.054l3.215 3.013 6.749-7.267a1.096 1.096 0 011.553-.054z"
        fill="#fff"
      />
    </svg>
  );
};

CheckboxFilled.defaultProps = {
  fill: oyster,
};

export default CheckboxFilled;
