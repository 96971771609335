import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const AddPlainIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C5.44771 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44771 0 6C0 6.55229 0.447715 7 1 7H5V11C5 11.5523 5.44771 12 6 12C6.55229 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44771 11.5523 5 11 5H7V1C7 0.447715 6.55229 0 6 0Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

AddPlainIcon.defaultProps = {
  fill: defaultIconColor,
};

export default AddPlainIcon;
