import { ReactElement, useState, useEffect, forwardRef, Ref, ElementType, FC } from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import { TransitionProps } from '@material-ui/core/transitions';
import { useAlertStyles, useSnackbarStyles, useIconStyles } from './styles';

import CheckboxFilledIcon from '@icons/CheckboxFilled';
import WarningIcon from '@icons/Warning';
import CrossIcon from '@icons/Cross';
import { IconProps } from '@icons/types';

const SuccessIcon = () => <CheckboxFilledIcon defaultColor data-testid="success-icon" />;
const ErrorIcon = () => <WarningIcon defaultColor data-testid="error-icon" />;
const ExitIcon = () => <CrossIcon defaultColor />;

export interface ToastProps extends AlertProps {
  vertical?: 'top' | 'bottom';
  horizontal?: 'center' | 'left' | 'right';
  message?: string;
  type: 'success' | 'error';
  duration?: number;
  customIcon?: ElementType | FC<IconProps>;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>,
) {
  return (
    <Slide
      {...props}
      direction="down"
      timeout={{
        enter: 300,
        exit: 300,
      }}
      ref={ref}
    />
  );
});

function Toast({
  title = '',
  message = '',
  type = 'success',
  vertical = 'top',
  horizontal = 'center',
  duration,
  onClose,
  customIcon: CustomIcon,
}: ToastProps): ReactElement {
  const [isOpen, setIsOpen] = useState(true);
  const alertClasses = useAlertStyles({ type });
  const snackbarClasses = useSnackbarStyles();
  const buttonClasses = useIconStyles();
  const isError = type === 'error';
  const AlertIcon = isError ? ErrorIcon : SuccessIcon;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration]);
  return (
    <Snackbar
      TransitionComponent={Transition}
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      classes={snackbarClasses}
    >
      <Alert
        className={isError ? 'toast-Error' : 'toast-Success'}
        classes={alertClasses}
        elevation={2}
        data-testid="toastComponent"
        variant="filled"
        icon={CustomIcon ? <CustomIcon /> : <AlertIcon />}
        action={
          <Button
            classes={buttonClasses}
            onClick={onClose}
            data-testid="toastAction"
            color="inherit"
            size="small"
          >
            <ExitIcon />
          </Button>
        }
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
