import { FC } from 'react';
import { IconProps, oyster } from '../types';

const GoIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13.0001C2.44772 13.0001 2 12.5524 2 12.0001C2 11.4478 2.44772 11.0001 3 11.0001L18.5858 11.0001L12.2929 4.7072C11.9024 4.31668 11.9024 3.68351 12.2929 3.29299C12.6834 2.90246 13.3166 2.90246 13.7071 3.29299L21.7071 11.293C22.0976 11.6835 22.0976 12.3167 21.7071 12.7072L13.7071 20.7072C13.3166 21.0977 12.6834 21.0977 12.2929 20.7072C11.9024 20.3167 11.9024 19.6835 12.2929 19.293L18.5858 13.0001L3 13.0001Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

GoIcon.defaultProps = {
  fill: oyster,
};

export default GoIcon;
