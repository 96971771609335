import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: `0 ${theme.spacing(2)}px 24px ${theme.spacing(2)}px`,
      backgroundColor: theme.oysterTheme.pearl,
      borderRadius: 10,
      boxShadow: '0px 0px 16px rgba(3, 3, 3, 0.08)',
    },
    iconContainer: {
      width: '10%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 24,
      paddingRight: theme.spacing(1),
    },
    dataContainer: {
      width: '90%',
    },
    title: {
      fontWeight: 600,
      color: theme.oysterTheme.charcoal,
      fontSize: 18,
      lineHeight: 1.3,
      paddingTop: theme.spacing(3),
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
    },
    error: {
      color: theme.oysterTheme.ferrari,
    },
    info: {
      color: theme.oysterTheme.oyster,
    },
    additionalInfoContainer: {
      marginTop: 10,
      marginBottom: 42,
      fontSize: 12,
    },
  }),
);

export default useStyles;
