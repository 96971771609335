import { FC, MouseEventHandler } from 'react';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import FormButton from '@components/FormButton';
import { IconProps } from '@icons/types';

import useStyles from './styles';

export interface IBanner {
  title: string;
  icon: FC<IconProps>;
  text: string;
  buttonText: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isError?: boolean;
  buttonId?: string;
  additionalInfo?: string | React.ReactNode;
}

const Banner: FC<IBanner> = ({
  title,
  icon: Icon,
  text,
  buttonText,
  onClick,
  isError,
  buttonId,
  additionalInfo,
}) => {
  const css = useStyles();

  return (
    <div data-testid="banner" className={css.container}>
      <div className={css.iconContainer}>
        <Icon fontSize={42} className={isError ? css.error : css.info} />
      </div>
      <div className={css.dataContainer}>
        <Typography
          variant="h3"
          className={clsx({
            [css.title]: true,
            [css.error]: isError,
          })}
        >
          {title}
        </Typography>
        <p className={css.text}>{text}</p>
        {additionalInfo && <div className={css.additionalInfoContainer}>{additionalInfo}</div>}
        {onClick && (
          <FormButton
            data-testid="banner-button"
            color="primary"
            label={buttonText}
            onClick={onClick}
            id={buttonId}
          />
        )}
      </div>
    </div>
  );
};

export default Banner;
