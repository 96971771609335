import { makeStyles, createStyles } from '@material-ui/core/styles';

const useLoaderStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }),
);

export default useLoaderStyles;
