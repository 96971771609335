import { FC } from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface ILoader {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spinner: {
      color: theme.oysterTheme.pine,
    },
  }),
);

const AppCircularLoader: FC<ILoader> = ({ className }) => {
  const css = useStyles();

  return (
    <div className={clsx(css.container, className)} data-testid="circularLoader">
      <CircularProgress className={css.spinner} size={50} thickness={5} />
    </div>
  );
};

export default AppCircularLoader;
