import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 266,
      fontWeight: 700,
      fontSize: 16,
    },
    containedPrimary: {
      background: theme.oysterTheme.pine,
      '&:hover': {
        background: theme.oysterTheme.pine,
      },
    },
    disabled: {
      background: theme.oysterTheme.stone,
      color: theme.oysterTheme.dolphin,
    },
  }),
);

export default useStyles;
