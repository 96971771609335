import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const SubtractPlainIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 2"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H1C0.447715 0 0 0.447716 0 1C0 1.55229 0.447715 2 1 2H5L7 2H11C11.5523 2 12 1.55229 12 1C12 0.447716 11.5523 0 11 0H7H5Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

SubtractPlainIcon.defaultProps = {
  fill: defaultIconColor,
};

export default SubtractPlainIcon;
