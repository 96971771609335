import { FC } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const LOADER_URL = 'https://oyster-public-assets.s3.us-west-2.amazonaws.com/public/loader1.gif';

interface ILoader {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.oysterTheme.pearl,
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spinner: {
      width: 100,
      height: 100,
    },
  }),
);

const AppIconLoader: FC<ILoader> = ({ className }) => {
  const css = useStyles();

  return (
    <div className={clsx(css.container, className)} data-testid="iconLoader">
      <img className={css.spinner} src={LOADER_URL} alt="Loading" />
    </div>
  );
};

export default AppIconLoader;
