import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      borderRadius: theme.spacing(1),
      boxShadow: '0 0 16px rgba(0, 0, 0, 0.2)',
      zIndex: 2,
    },
    content: {
      padding: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2),
      fontSize: 24,
    },
    message: {
      wordWrap: 'break-word',
    },
    imageContainer: {
      position: 'relative',
      overflow: 'hidden',
    },
    extraInfoButton: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      padding: theme.spacing(1),
      zIndex: 5,
      fontSize: 16,
      color: theme.palette.common.white,
      '&.active': {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
      },
    },
    extraInfoContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.white,
      zIndex: 3,
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      overflow: 'auto',
    },
    actionButton: {
      paddingTop: theme.spacing(2),
      marginTop: -theme.spacing(1),
    },
  }),
);

export default useStyles;
