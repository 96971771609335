import { FC } from 'react';
import { IconProps, alertRed } from './types';

const WarningIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.1654 4.99309C10.076 3.92018 10.9227 3 11.9993 3C13.0759 3 13.9226 3.92018 13.8332 4.99309L13.0823 14.0035C13.0354 14.5667 12.5645 15 11.9993 15C11.4341 15 10.9632 14.5667 10.9162 14.0035L10.1654 4.99309ZM13.9987 18.9999C13.9987 20.1045 13.1032 20.9999 11.9987 20.9999C10.8941 20.9999 9.99865 20.1045 9.99865 18.9999C9.99865 17.8953 10.8941 16.9999 11.9987 16.9999C13.1032 16.9999 13.9987 17.8953 13.9987 18.9999Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

WarningIcon.defaultProps = {
  fill: alertRed,
};

export default WarningIcon;
