import { forwardRef } from 'react';
import clsx from 'clsx';

import MaterialButton, {
  ButtonClassKey,
  ButtonProps as MaterialButtonProps,
} from '@material-ui/core/Button';
import CircularProgress, { CircularProgressClassKey } from '@material-ui/core/CircularProgress';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import mergewith from 'lodash.mergewith';

import useStyles from './styles';

type ButtonClasses = Partial<ClassNameMap<ButtonClassKey>>;

export interface ButtonProps extends MaterialButtonProps {
  isSubmitting?: boolean;
  label: string;
  loaderSize?: number;
  classes?: ButtonClasses;
  circularProgressClasses?: Partial<ClassNameMap<CircularProgressClassKey>>;
}

const ButtonComposed = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    label,
    variant = 'contained',
    loaderSize,
    isSubmitting,
    classes,
    circularProgressClasses,
    ...btnProps
  } = props;
  const customClasses = useStyles();
  const buttonClasses: ButtonClasses = mergewith(customClasses, classes, clsx);

  return (
    <MaterialButton ref={ref} variant={variant} classes={buttonClasses} {...btnProps}>
      {isSubmitting ? (
        <>
          {label}&nbsp;
          <CircularProgress
            data-testid="button-circular-progress"
            color="inherit"
            size={loaderSize || 28}
            thickness={4}
            classes={circularProgressClasses}
          />
        </>
      ) : (
        label
      )}
    </MaterialButton>
  );
});

ButtonComposed.displayName = 'ButtonComposed';

export default ButtonComposed;
