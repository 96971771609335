import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const CrossIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={defaultColor ? props.fill : 'currentColor'}
        fillRule="evenodd"
        d="M23.468 3.077a1.802 1.802 0 00-2.55-2.55L12 9.448 3.08.528a1.802 1.802 0 10-2.549 2.55l8.919 8.918-8.923 8.923a1.802 1.802 0 002.549 2.549L12 14.545l8.923 8.923a1.802 1.802 0 002.55-2.549l-8.924-8.923 8.919-8.92z"
        clipRule="evenodd"
      />
    </svg>
  );
};

CrossIcon.defaultProps = {
  fill: defaultIconColor,
};

export default CrossIcon;
