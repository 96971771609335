import { FC } from 'react';
import { IconProps, obsidian, pine } from '../types';

const OysterYaydoo: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="127"
      height="40"
      viewBox="0 0 127 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_920_9745)">
        <path
          d="M16.4082 24.278C13.5847 24.2746 10.8778 23.149 8.88122 21.148C6.88468 19.147 5.76154 16.434 5.75815 13.6042C5.75815 11.5282 6.581 9.53725 8.04566 8.06931C9.51033 6.60137 11.4968 5.77669 13.5682 5.77669C15.6395 5.77669 17.6261 6.60137 19.0907 8.06931C20.5554 9.53725 21.3782 11.5282 21.3782 13.6042H19.9582C19.9582 11.9056 19.285 10.2767 18.0866 9.07565C16.8883 7.87461 15.2629 7.19987 13.5682 7.19987C11.8735 7.19987 10.2481 7.87461 9.04976 9.07565C7.85139 10.2767 7.17816 11.9056 7.17816 13.6042C7.17816 16.0576 8.15061 18.4105 9.88158 20.1454C11.6125 21.8802 13.9602 22.8548 16.4082 22.8548C18.8562 22.8548 21.2039 21.8802 22.9348 20.1454C24.6658 18.4105 25.6383 16.0576 25.6383 13.6042C25.6383 10.3958 24.3666 7.31892 22.103 5.05029C19.8394 2.78166 16.7694 1.50716 13.5682 1.50716C10.367 1.50716 7.29695 2.78166 5.03337 5.05029C2.7698 7.31892 1.49813 10.3958 1.49813 13.6042H0.078125C0.078125 10.0184 1.4994 6.57948 4.02927 4.04395C6.55915 1.50843 9.99041 0.0839844 13.5682 0.0839844C17.146 0.0839844 20.5772 1.50843 23.1071 4.04395C25.637 6.57948 27.0583 10.0184 27.0583 13.6042C27.0549 16.434 25.9317 19.147 23.9352 21.148C21.9387 23.149 19.2317 24.2746 16.4082 24.278Z"
          fill="#3BD4AE"
        />
        <path
          d="M16.408 27.1243C12.8315 27.1202 9.40267 25.6944 6.87368 23.1598C4.3447 20.6252 2.9221 17.1887 2.91797 13.6042C2.91797 10.7733 4.04003 8.05835 6.0373 6.05662C8.03457 4.05489 10.7435 2.93033 13.568 2.93033C16.3926 2.93033 19.1015 4.05489 21.0987 6.05662C23.096 8.05835 24.2181 10.7733 24.2181 13.6042C24.2181 15.6801 23.3952 17.6711 21.9306 19.139C20.4659 20.607 18.4794 21.4316 16.408 21.4316C14.3367 21.4316 12.3502 20.607 10.8855 19.139C9.42084 17.6711 8.598 15.6801 8.598 13.6042H10.018C10.018 15.3027 10.6912 16.9316 11.8896 18.1327C13.088 19.3337 14.7133 20.0085 16.408 20.0085C18.1028 20.0085 19.7281 19.3337 20.9265 18.1327C22.1248 16.9316 22.7981 15.3027 22.7981 13.6042C22.7981 11.1507 21.8256 8.79779 20.0947 7.06296C18.3637 5.32812 16.016 4.35351 13.568 4.35351C11.1201 4.35351 8.77236 5.32812 7.04139 7.06296C5.31042 8.79779 4.33798 11.1507 4.33798 13.6042C4.33798 16.8125 5.60964 19.8894 7.87322 22.158C10.1368 24.4267 13.2069 25.7012 16.408 25.7012C19.6092 25.7012 22.6793 24.4267 24.9429 22.158C27.2064 19.8894 28.4781 16.8125 28.4781 13.6042H29.8981C29.894 17.1887 28.4714 20.6252 25.9424 23.1598C23.4134 25.6944 19.9846 27.1202 16.408 27.1243Z"
          fill="#3BD4AE"
        />
        <path
          d="M36.5742 11.4694C36.5742 6.23637 40.6582 2.36105 46.2303 2.36105C51.7768 2.36105 55.8863 6.21075 55.8863 11.4694C55.8863 16.728 51.7768 20.5777 46.2303 20.5777C40.6582 20.5777 36.5742 16.7024 36.5742 11.4694ZM51.7513 11.4694C51.7513 8.1235 49.3813 5.83361 46.2303 5.83361C43.0793 5.83361 40.7093 8.1235 40.7093 11.4694C40.7093 14.8153 43.0793 17.1052 46.2303 17.1052C49.3813 17.1052 51.7513 14.8153 51.7513 11.4694Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M70.0859 19.1958L71.3639 16.3082C72.5823 17.0966 74.5178 17.7314 76.192 17.7314C78.0181 17.7314 78.606 17.2133 78.606 16.4505C78.606 14.2133 70.2279 16.368 70.2279 10.9001C70.2279 8.30568 72.784 6.48828 76.76 6.48828C78.6372 6.48828 80.7204 7.00632 82.014 7.76914L80.736 10.6155C79.3912 9.85267 78.0025 9.47695 76.76 9.47695C74.985 9.47695 74.204 10.1885 74.204 10.9001C74.204 13.2398 82.44 10.9172 82.44 16.3082C82.44 18.8514 79.9919 20.7201 75.908 20.7201C73.599 20.7201 71.354 20.0355 70.0859 19.1958Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M93.3739 19.8661C92.5673 20.4539 91.3177 20.8624 90.1079 20.8624C86.9072 20.8624 84.9958 19.0122 84.9958 15.7389V10.1885H83.0078V7.19987H84.9958V3.78424H88.9718V7.19987H91.6699V10.1885H88.9718V15.7389C88.9718 16.9145 89.5796 17.6517 90.6389 17.6517C91.2183 17.6517 91.809 17.3514 92.2379 17.0198L93.3739 19.8661Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M107.356 14.7228H97.0655C97.4446 16.4306 98.886 17.4695 100.961 17.4695C102.402 17.4695 103.517 17.0554 104.454 16.1659L106.584 18.443C105.319 19.8932 103.358 20.7201 100.904 20.7201C96.1993 20.7201 93.0938 17.673 93.0938 13.6042C93.0938 9.50969 96.1879 6.48828 100.336 6.48828C104.332 6.48828 107.436 9.2037 107.436 13.6042C107.432 13.9087 107.38 14.3926 107.356 14.7228ZM97.0101 12.4087H103.714C103.43 10.6795 102.145 9.56235 100.374 9.56235C98.5778 9.56092 97.2884 10.6539 97.0101 12.4087Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M117.085 6.48828V10.1885C116.758 10.1629 116.509 10.1501 116.208 10.1501C114.051 10.1501 112.541 11.2887 112.541 13.8888V20.4354H108.707V6.77292H112.399V8.62305C113.352 7.30803 115.003 6.48828 117.085 6.48828Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M114.816 18.1356C114.816 16.7736 115.823 15.8813 117.088 15.8813C118.354 15.8813 119.36 16.7736 119.36 18.1356C119.36 18.7395 119.121 19.3187 118.695 19.7457C118.269 20.1727 117.691 20.4127 117.088 20.4127C116.486 20.4127 115.908 20.1727 115.482 19.7457C115.056 19.3187 114.816 18.7395 114.816 18.1356Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M66.9793 6.63058L63.3881 15.4543L59.7415 6.63058H55.6391L61.3191 20.0696L61.268 20.1977C60.7341 21.4245 60.1235 21.9055 59.0301 21.9055C58.242 21.9055 57.3431 21.6793 56.7325 21.1427L55.3125 23.8468C56.2028 24.6395 57.8785 25.1276 59.2772 25.1276C61.7451 25.1276 63.5031 24.1314 64.8251 20.8069L70.7948 6.63058H66.9793Z"
          fill={defaultColor ? props.fill : 'currentColor'}
        />
        <path
          d="M112.847 30.9033C112.542 30.5907 112.176 30.3441 111.771 30.1789C111.367 30.0136 110.933 29.9332 110.496 29.9426C110.057 29.9329 109.62 30.0132 109.213 30.1784C108.806 30.3436 108.437 30.5903 108.129 30.9033C107.82 31.2061 107.577 31.5691 107.414 31.9697C107.25 32.3704 107.171 32.8002 107.18 33.2328C107.172 33.667 107.251 34.0984 107.414 34.5009C107.577 34.9034 107.821 35.2685 108.129 35.574C108.439 35.8841 108.808 36.1281 109.215 36.2912C109.622 36.4543 110.058 36.5332 110.496 36.523C110.931 36.5299 111.363 36.4498 111.767 36.2872C112.17 36.1246 112.537 35.8829 112.846 35.5763C113.155 35.2697 113.399 34.9044 113.565 34.5019C113.73 34.0994 113.813 33.6679 113.81 33.2328C113.818 32.7992 113.736 32.3686 113.571 31.9678C113.405 31.567 113.159 31.2047 112.847 30.9033ZM111.85 34.662C111.678 34.8486 111.468 34.996 111.234 35.0945C111 35.193 110.748 35.2403 110.494 35.2331C110.237 35.2393 109.981 35.1917 109.744 35.0933C109.506 34.995 109.291 34.8481 109.114 34.662C108.76 34.2713 108.571 33.7595 108.585 33.2328C108.568 32.7092 108.758 32.2002 109.114 31.8152C109.289 31.6249 109.502 31.4742 109.741 31.3734C109.979 31.2726 110.236 31.224 110.494 31.2308C110.749 31.2236 111.002 31.2722 111.236 31.3731C111.471 31.4741 111.68 31.625 111.85 31.8152C112.028 32.0043 112.168 32.2269 112.261 32.4701C112.354 32.7134 112.398 32.9725 112.39 33.2328C112.404 33.7591 112.21 34.2697 111.85 34.6537V34.662Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M105.65 30.8613C105.345 30.5487 104.979 30.3022 104.574 30.1369C104.17 29.9717 103.736 29.8912 103.299 29.9006C102.86 29.8911 102.424 29.9714 102.017 30.1366C101.611 30.3018 101.242 30.5484 100.934 30.8613C100.625 31.1643 100.382 31.5272 100.219 31.9279C100.055 32.3285 99.976 32.7583 99.9852 33.1908C99.9753 33.6323 100.054 34.0714 100.216 34.4823C100.378 34.8931 100.62 35.2674 100.929 35.5832C101.238 35.899 101.607 36.1499 102.014 36.3212C102.421 36.4925 102.858 36.5808 103.3 36.5808C103.741 36.5808 104.179 36.4925 104.586 36.3212C104.993 36.1499 105.362 35.899 105.67 35.5832C105.979 35.2674 106.222 34.8931 106.384 34.4823C106.546 34.0714 106.624 33.6323 106.614 33.1908C106.622 32.7571 106.541 32.3265 106.375 31.9257C106.209 31.5249 105.962 31.1626 105.65 30.8613ZM104.643 34.6201C104.473 34.8063 104.265 34.9537 104.033 35.0522C103.801 35.1508 103.551 35.1981 103.299 35.1911C103.042 35.1973 102.786 35.1497 102.548 35.0514C102.311 34.953 102.096 34.8061 101.918 34.6201C101.566 34.2288 101.376 33.7173 101.389 33.1908C101.375 32.6675 101.565 32.1591 101.918 31.7733C102.094 31.5835 102.308 31.4333 102.546 31.3325C102.784 31.2317 103.041 31.1827 103.299 31.1888C103.552 31.1831 103.803 31.2324 104.035 31.3333C104.267 31.4342 104.474 31.5844 104.643 31.7733C104.822 31.9623 104.962 32.1847 105.055 32.428C105.148 32.6712 105.192 32.9304 105.185 33.1908C105.201 33.7197 105.007 34.2336 104.646 34.6201H104.643Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M97.7428 26.8724V30.8798C97.5205 30.5899 97.2328 30.3568 96.903 30.1995C96.5362 30.004 96.1263 29.9036 95.7106 29.9073C95.2989 29.9005 94.8906 29.9829 94.5138 30.1487C94.1369 30.3145 93.8003 30.5599 93.5272 30.868C92.9608 31.5119 92.6484 32.34 92.6484 33.1975C92.6484 34.055 92.9608 34.8832 93.5272 35.527C93.8003 35.8351 94.1369 36.0805 94.5138 36.2463C94.8906 36.4121 95.2989 36.4945 95.7106 36.4877C96.1248 36.4908 96.5337 36.3947 96.903 36.2072C97.2326 36.0445 97.52 35.8076 97.7428 35.5153V36.355H99.1351V26.8724H97.7428ZM97.3297 34.6385C97.1591 34.8282 96.9495 34.9787 96.7152 35.0796C96.4809 35.1805 96.2276 35.2294 95.9726 35.223C95.7177 35.2307 95.4642 35.1823 95.23 35.0813C94.9958 34.9803 94.7866 34.8292 94.6172 34.6385C94.2757 34.2361 94.0882 33.7254 94.0882 33.1975C94.0882 32.6696 94.2757 32.1589 94.6172 31.7565C94.7916 31.5719 95.0019 31.4249 95.2351 31.3244C95.4683 31.2239 95.7195 31.1721 95.9734 31.1721C96.2274 31.1721 96.4786 31.2239 96.7118 31.3244C96.945 31.4249 97.1553 31.5719 97.3297 31.7565C97.6843 32.1512 97.8736 32.6671 97.8587 33.1975C97.8743 33.726 97.6847 34.2401 97.3297 34.6318V34.6385Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M88.7471 38.9851H87.2153L88.5707 35.999L85.8516 30.0148H87.385L89.3181 34.4907L91.2647 30.0148H92.8098L88.7471 38.9851Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M85.0121 30.611C84.5317 30.1474 83.8526 29.9162 82.9748 29.9174C82.5095 29.913 82.0483 30.0039 81.6194 30.1844C81.2275 30.3474 80.8815 30.604 80.6117 30.9318C80.3442 31.2634 80.1848 31.669 80.1549 32.094H81.4985C81.5193 31.934 81.574 31.7802 81.6588 31.643C81.7437 31.5057 81.8568 31.3881 81.9906 31.2979C82.2788 31.1078 82.6179 31.01 82.9631 31.0174C83.3387 31.0016 83.7057 31.1329 83.9859 31.3836C84.2596 31.6254 84.3923 31.9966 84.3923 32.4837V32.5996H82.7514C81.8747 32.5996 81.1995 32.7759 80.7259 33.1286C80.4992 33.2926 80.3163 33.5098 80.1933 33.761C80.0703 34.0123 80.011 34.29 80.0205 34.5696C80.0188 34.9069 80.1069 35.2384 80.2758 35.5303C80.4609 35.8313 80.7293 36.0722 81.0484 36.224C81.4591 36.415 81.9091 36.5065 82.3618 36.491C82.8314 36.5132 83.2965 36.3901 83.6937 36.1383C84.0206 35.9162 84.2863 35.6153 84.4662 35.2633V36.3382H85.7427V32.4753C85.7393 31.6859 85.4975 31.0712 85.0121 30.611ZM84.1135 34.4538C83.9823 34.7318 83.7809 34.9709 83.5291 35.1474C83.2589 35.325 82.9402 35.4142 82.6171 35.4027C82.3059 35.4213 81.9974 35.3356 81.7404 35.1592C81.6374 35.0791 81.555 34.9756 81.5001 34.8573C81.4452 34.739 81.4193 34.6093 81.4246 34.4789C81.4244 34.3454 81.4546 34.2136 81.5128 34.0935C81.5709 33.9733 81.6557 33.8679 81.7605 33.7853C81.9856 33.5955 82.3702 33.5048 82.8992 33.5048H84.389C84.3568 33.8351 84.2646 34.1567 84.1169 34.4538H84.1135Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M77.0411 36.3399V32.5441L73.6367 27.3561H75.37L77.7717 31.0779L80.1852 27.3561H81.9319L78.5141 32.5559V36.3399H77.0411Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M68.9065 38.3268H67.9861L69.0324 36.0729L66.9297 31.523H67.8417L69.4926 35.1709L71.1335 31.523H72.0539L68.9065 38.3268Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
        <path
          d="M64.6549 36.4171C64.3126 36.4199 63.9748 36.3392 63.6707 36.182C63.3891 36.0443 63.1466 35.8381 62.9653 35.5824V36.3214H62.1406V29.1246H62.9653V32.262C63.1475 32.0072 63.3898 31.8012 63.6707 31.6624C63.975 31.506 64.3127 31.4253 64.6549 31.4273C64.9708 31.4206 65.2844 31.4823 65.5743 31.6081C65.8642 31.7339 66.1235 31.9209 66.3344 32.1562C66.7689 32.6408 67.0091 33.2688 67.0091 33.9197C67.0091 34.5706 66.7689 35.1985 66.3344 35.6832C66.1244 35.9199 65.8654 36.1082 65.5754 36.2349C65.2854 36.3617 64.9713 36.4238 64.6549 36.4171ZM63.36 35.1609C63.5114 35.3186 63.6931 35.4442 63.8943 35.53C64.0955 35.6157 64.3119 35.6599 64.5306 35.6599C64.7493 35.6599 64.9657 35.6157 65.1669 35.53C65.368 35.4442 65.5498 35.3186 65.7012 35.1609C65.9981 34.8168 66.1615 34.3775 66.1615 33.923C66.1615 33.4686 65.9981 33.0293 65.7012 32.6852C65.5498 32.5274 65.368 32.4019 65.1669 32.3161C64.9657 32.2304 64.7493 32.1862 64.5306 32.1862C64.3119 32.1862 64.0955 32.2304 63.8943 32.3161C63.6931 32.4019 63.5114 32.5274 63.36 32.6852C63.0621 33.0288 62.898 33.4683 62.898 33.923C62.898 34.3778 63.0621 34.8173 63.36 35.1609Z"
          fill={defaultColor ? props.color : 'currentColor'}
        />
      </g>
      <path
        d="M121.934 3.7267C121.934 2.24899 122.985 1.17569 124.474 1.17569C125.949 1.17569 127.001 2.24899 127.001 3.7267C127.001 5.20222 125.949 6.29082 124.474 6.29082C122.985 6.29082 121.934 5.20222 121.934 3.7267ZM122.474 3.7267C122.474 4.9399 123.25 5.79024 124.476 5.79024C125.689 5.79024 126.489 4.9399 126.489 3.7267C126.489 2.51349 125.689 1.66097 124.476 1.66097C123.247 1.66097 122.474 2.51349 122.474 3.7267ZM125.011 4.00213L125.713 5.04046L124.924 5.05357L124.325 4.05022H124.113V5.05139H123.462V2.36266H124.662C125.223 2.36266 125.599 2.68619 125.599 3.19988C125.599 3.6021 125.374 3.88846 125.011 4.00213ZM124.111 2.95069V3.51466H124.625C124.799 3.51466 124.95 3.42722 124.95 3.22612C124.95 3.02501 124.799 2.95069 124.625 2.95069H124.111Z"
        fill={defaultColor ? props.color : 'currentColor'}
      />
      <defs>
        <clipPath id="clip0_920_9745">
          <rect width="119.414" height="39.133" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

OysterYaydoo.defaultProps = {
  fill: pine,
  color: obsidian,
};

export default OysterYaydoo;
