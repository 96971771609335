import { FC } from 'react';
import clsx from 'clsx';
import mergeWith from 'lodash.mergewith';

import Button, { ButtonProps } from '../Button';
import useStyles from './styles';

export interface FormButtonProps extends ButtonProps {}

const FormButton: FC<FormButtonProps> = ({ classes, ...props }) => {
  const customClasses = useStyles();
  const buttonClasses = mergeWith(classes, customClasses, clsx);
  return <Button classes={{ ...buttonClasses }} {...props} />;
};

export default FormButton;
