import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0.5em 1em',
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 600,
      borderRadius: '0 0 8px 8px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: '0.3em 1em',
        height: 35,
      },
    },
    endIcon: {
      justifySelf: 'flex-end',
    },
  }),
);

export default useStyles;
